.main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  width: clamp(160px, 600px, 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 100vh;
  text-align: center;
}

.container h1 {
  font-size: 2.5rem;
}

.container p {
  font-size: 1.3rem;
}